.wrapper {
  @apply list-none
    mt-2
    relative
    bg-white
    border
    border-neutrals-2
    rounded-[10px]
    px-2
    shadow-8selectList
    pt-2.5;
}

.listBlur {
  @apply bg-gradient-to-t
      from-gr-3-2
      to-gr-3-3
    h-14
    absolute
    bottom-0
    left-0
    z-[1]
    pointer-events-none
    w-[100%];
}

.popoverWithMouseBody {
  @apply relative z-40;
}

.popoverWithMouseModal {
  @apply absolute right-[85%] top-[60%] outline-none
  bg-white
  shadow-10tabs
  py-6
  px-6
  md:p-6
  rounded-[20px]
  min-w-[300px]
  max-w-max;
}

.popoverWithMouseTrigger {
  @apply cursor-pointer;
}
