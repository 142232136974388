.icon {
  @apply fill-neutrals-3;
}

.popover {
  @apply left-0 right-0 !important;
}

.popover::-webkit-scrollbar {
  @apply w-1
  bg-neutrals-1
  rounded;
}

.popover::-webkit-scrollbar-thumb {
  @apply w-1
  bg-neutrals-2
  rounded;
}

.defaultNumber {
  @apply flex
  text-neutrals-8
  md:text-lg
  leading-6
  font-medium
  absolute
  left-6
  top-[18px];
}

.defaultNumberValue {
  @apply relative
  bottom-[1px];
}

.listBlur {
  @apply bg-gradient-to-t
      from-gr-3-2
      to-gr-3-3
    h-14
    absolute
    bottom-0
    left-0
    z-[1]
    pointer-events-none
    rounded-[10px]
    w-[100%];
}

.checkText {
  @apply text-neutrals-5
  ml-2
  md:text-sm
  text-xs;
}
.error {
  @apply md:mt-2
  mt-1;
}

.copyButtonLabel {
  @apply text-[11px]
  leading-[14px]
  md:right-[14px];
}

.checkLabel {
  @apply right-10
  absolute
  top-3
  text-[10px]
  leading-[10px]
  py-1.5
  px-2.5
  md:text-[11px]
  md:leading-[15px]
  md:py-2
  md:px-3.5;
}

.container {
  @apply relative;
}
