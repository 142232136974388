.container {
  @apply flex
    flex-col
    items-center
    md:py-8
    py-6
    bg-primary-0
    rounded-2xl
    border
    border-dashed
    border-neutrals-wallet-card
    cursor-pointer;
}

.dropzone {
  @apply flex
    flex-col
    items-center;
}

.icon {
  @apply md:mb-5
    mb-4
    fill-neutrals-wallet-card;
}

.title {
  @apply md:text-sm
  text-xs
  text-neutrals-8
    mb-2;
}

.files {
  @apply text-neutrals-7
  font-semibold pl-6
  text-sm;
}

.filesMultiple {
  @apply grid gap-2 pl-8 max-h-[200px] overflow-auto list-decimal;
}

.primary {
  @apply font-semibold
    text-sm
    text-primary-2;
}

.neutral {
  @apply font-semibold
  text-sm;
}

.description {
  @apply text-xs
    text-neutrals-5;
}
