.wrapper {
  @apply inline-flex
  relative
  w-[100%]
  flex-col;
}

.label {
  @apply text-neutrals-7
  bg-white
  absolute
  -top-2
  z-[1]
  px-1.5
  ml-3
  max-w-max
  text-xs;
}

.labelLarge {
  @apply ml-[18px];
}

.inputField {
  @apply relative
  outline-none
  flex
  h-[inherit]
  items-center
  justify-center
  rounded-xl
  pl-[18px]
  pr-3.5
  border
  transition-colors
  border-neutrals-2;
}

.inputFieldLarge {
  @apply pl-6
  pr-5;
}

.inputFieldWithPrimaryButton {
  @apply pr-2;
}

.focused,
.filled {
  @apply border-primary-2;
}

/*Sizes*/

.medium {
  @apply h-12
  text-base;
}
.large {
  @apply h-14
  text-lg;
}

.input {
  @apply w-[100%]
  h-[inherit]
  border-none
  outline-none
  bg-transparent
  rounded-[inherit]
  font-medium
  placeholder:font-normal
  placeholder:text-neutrals-3
  text-neutrals-8
  disabled:cursor-not-allowed
  disabled:text-neutrals-4;
}

.textarea {
  @apply resize-none rounded-none h-[75%];
}

.error {
  @apply md:mt-2
  mt-1;
}

.errorText {
  @apply text-red-2
  ml-2
  md:text-sm
  text-[8px] leading-3 xxs:text-xs;
}

.icon {
  @apply min-w-[20px]
  max-w-[20px];
}
.iconLeft {
  @apply mr-1;
}

.button {
  @apply cursor-pointer
  bg-transparent
  flex
  items-center
  justify-center
  disabled:cursor-not-allowed;
}

.buttonText {
  @apply text-neutrals-8
  text-base
  mx-1.5;
}

.disabled {
  @apply cursor-not-allowed
  text-neutrals-4
  border-neutrals-1;
}

.labelDisabled {
  @apply cursor-not-allowed
  text-neutrals-3;
}

.buttonIconDisabled {
  @apply fill-neutrals-2;
}

.textWithIconRight {
  @apply inline-block
  text-neutrals-9
  text-base
  leading-[22.4px]
  font-medium;
}

.input:-webkit-autofill,
.input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.copyButtonLabel {
  @apply text-[11px]
  leading-[14px]
  md:right-[14px];
}

.checkLabel {
  @apply md:right-0
  text-[10px]
  leading-[10px]
  py-1.5
  px-2.5
  md:text-[11px]
  md:leading-[15px]
  md:py-2
  md:px-3.5;
}

.checkText {
  @apply text-neutrals-5
  ml-2
  md:text-sm
  text-xs;
}

.secondButtonIcon {
  @apply mr-2;
}

.buttonIconWrapper {
  @apply flex items-center;
}
