.listBlur {
  @apply bg-gradient-to-t
    from-gr-3-2
    to-gr-3-3
  h-14
  absolute
  bottom-0
  left-0
  z-10
  w-[100%];
}

.listContainer::-webkit-scrollbar {
  @apply w-1
  bg-neutrals-1
  rounded;
}

.listContainer::-webkit-scrollbar-thumb {
  @apply w-1
  bg-neutrals-2
  rounded;
}

.isScrollable {
  @apply overflow-y-auto
  pr-3;
}

.option {
  @apply text-left
  cursor-pointer
  bg-transparent
  h-11
  outline-none
  transition-colors
  hover:bg-neutrals-1
  hover:text-neutrals-5
  rounded
  flex
  items-center
  md:text-base
  text-sm
  text-neutrals-9;
  /*[&:nth-child(3)]:cursor-auto*/
  /*[&:nth-child(3)]:hover:bg-transparent*/
  /*[&:nth-child(3)]:hover:text-neutrals-9;*/
}

.withIcon {
  @apply h-[initial]
  leading-[22px]
  py-[9px];
}

.isSelected {
  @apply hover:bg-transparent
  hover:text-neutrals-5
  text-neutrals-5;
}

.isPressed {
  @apply hover:bg-neutrals-2;
}

.radioLabel {
  @apply py-3
  w-[100%]
  justify-between;
}

.radioName {
  @apply text-sm
  font-semibold;
}

.radioNameWithIcon {
  @apply text-neutrals-6;
}

.singleOptionIcon {
  @apply mr-2;
}

.logoutOption {
  @apply h-10;
}

.logoutIcon {
  @apply stroke-red-2
  fill-transparent;
}

.logoutLabel {
  @apply text-red-2;
}

.lastOption {
  @apply h-[initial]
  py-1.5
  mt-[1px]
  !pl-2
  w-[100%]
  justify-start;
}

.lastOptionWrapper {
  @apply flex
  items-center
  border-t-[1px]
  mt-1.5
  pt-1
  rounded-none;
}
