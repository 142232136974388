.label {
  @apply inline-flex
    relative
    transition-colors
    justify-center
    items-center
    rounded-[20px]
    w-max;
}

.text {
  @apply relative
  inline-flex
    z-[1]
    font-medium;
}

/* Colors */

.red {
  @apply bg-red-0
  text-red-2
  fill-red-2;
}

.green {
  @apply bg-green-0
  text-green-2
  fill-green-2;
}

.blue {
  @apply bg-primary-0
  text-primary-2
  fill-primary-2;
}

.gray {
  @apply bg-neutrals-1
  text-neutrals-5
  fill-neutrals-5;
}

.yellow {
  @apply bg-yellow-0
  text-yellow-2
  fill-yellow-2;
}

.tangerine {
  @apply bg-tangerine-0
  text-tangerine-1
  fill-tangerine-1;
}

.medium {
  @apply px-3.5
    py-2
    text-sm
    leading-[15px];
}

.small {
  @apply px-2.5
    py-1.5
    text-[10px]
    leading-[10px];
}

.big {
  @apply px-3.5
    py-[11px]
    text-sm
    leading-[14px];
}
.icon {
  @apply relative
  flex
  items-center
    h-3
    w-3
    z-[1];
}

.iconSmall {
  @apply h-2.5
    w-2.5;
}

.left {
  @apply mr-1;
}

.right {
  @apply ml-1 order-2;
}
