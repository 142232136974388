.item {
  @apply rounded-full border-2 md:h-5 h-4 md:w-5 w-4 flex flex-col items-center relative flex-shrink-0 flex-grow-0 transition-colors duration-1000;
}

.list {
  @apply flex flex-row items-center md:pb-[30px] pb-6;
}

.listItem {
  @apply md:mx-3 mx-2.5;
}

.line {
  @apply md:w-[122px] w-[56px] h-[2px] rounded-[10px];
}

.hostedLine {
  @apply w-[102px]
  md:w-[116px];
}

.sellLine {
  @apply w-[90px]
  md:w-[120px];
}

.text {
  @apply absolute inline-block top-[calc(100%+10px)] md:text-sm text-xs font-normal whitespace-nowrap text-center transition-colors duration-1000;
}
