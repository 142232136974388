.flag {
  @apply w-4 h-4 overflow-hidden mr-2.5;
}
.flag > svg {
  @apply h-4;
}
.flag.input {
  @apply mr-2.5
        min-w-[16px];
}
.hostedFlag {
  @apply w-5 h-5 mr-3;
}
.hostedFlagMedium {
  @apply mr-2.5;
}
.hostedFlag > svg {
  @apply h-5;
}
.hostedFlag.input {
  @apply min-w-[20px]
    mr-3;
}

.row {
  @apply flex items-center;
}
.container {
  @apply relative
    bg-white
    flex
    w-full
    h-[56px]
    items-center
    justify-center
    rounded-xl
    border
    cursor-pointer
    transition-colors
    border-neutrals-2
    shadow-none
    font-medium
    text-lg
    px-0;
}

.medium {
  @apply h-12
  p-0
  border-none;
}

.container.focused {
  @apply border-primary-1;
}

.select {
  @apply relative
  z-[2]
  left-0;
}

.input {
  @apply outline-none
    border-none
    h-[54px]
    w-full
    rounded-[11px]
    placeholder:font-normal
    placeholder:text-neutrals-3
    rounded-tl-none
    rounded-bl-none;
}

.inputMedium {
  @apply h-[46px];
}

.valueWrapper {
  @apply z-[2]
  md:z-[1]
  text-base
  text-neutrals-9
  md:text-lg;
}

.widgetValueWrapper {
  @apply z-[0];
}

.valueName {
  @apply capitalize
    absolute
    text-sm
    md:left-24
    left-[90px]
    z-[-1];
}

.hostedValueName {
  @apply static;
}

.hostedValueWrapper {
  @apply relative
  text-neutrals-7
  text-base
  left-0
  z-0
  flex
  items-center;
}

.inputWrapper {
  @apply absolute
  md:left-6
  left-[17px]
  right-[140px]
  top-0
  bottom-0
  flex
  justify-start
  items-center;
}

.hostedInputWrapper {
  @apply left-[initial]
  z-[2]
  md:z-[initial]
  right-[30px]
  max-w-[122px]
  pointer-events-none
  md:max-w-[initial]
  md:right-0
  text-neutrals-7
  text-base;
}

.country {
  @apply mr-1;
}
.labelWrapper {
  @apply absolute
    px-[8px]
    left-[18px]
    top-[-8px]
    z-10
    text-neutrals-5
    text-xs
    bg-white;
}

.labelWrapperHosted {
  @apply text-neutrals-7
  px-1.5;
}

.labelWrapperMedium {
  @apply left-3
  font-normal
  text-neutrals-5
  px-1.5;
}
.label {
  @apply relative
    z-[10];
}

.currencyValue {
  @apply absolute
  right-4
  md:right-14;
}

.equal {
  @apply mr-1;
}

.subnameLabel {
  @apply text-[8px] ml-1;
}

.valueLabel {
  @apply pl-2;
}
