.placesContainer {
  @apply relative;
}

.dropdownContainer {
  @apply md:mt-2
        absolute
        left-0
        md:top-14
        top-[50px]
        bg-white
        z-[2]
        rounded-[10px]
        py-[10px]
        md:px-1.5
        px-1
        shadow-8selectList
    w-[100%];
}

.dropdownContainerHidden {
  @apply invisible;
}

.item {
  @apply text-left
        cursor-pointer
        bg-transparent
        md:px-4
        px-3
        py-1
        outline-none
        rounded-[10px]
        flex
        justify-between
        items-center
        md:text-base
        text-sm
        text-neutrals-9;
}

.wrapper {
  @apply inline-flex
  relative
  w-[100%]
  flex-col;
}

.label {
  @apply text-neutrals-7
  bg-white
  absolute
  -top-2
  z-[1]
  px-1.5
  ml-3
  max-w-max
  text-xs;
}

.labelLarge {
  @apply ml-[18px];
}

.inputField {
  @apply relative
  outline-none
  w-[100%]
  flex
  h-[inherit]
  items-center
  justify-center
  rounded-xl
  pl-[18px]
  pr-3.5
  border
  transition-colors
  border-neutrals-2;
}

.inputFieldLarge {
  @apply pl-6
  pr-5;
}

.focused,
.filled {
  @apply border-primary-2;
}

/*Sizes*/

.medium {
  @apply h-12
  text-base;
}
.large {
  @apply h-14
  text-lg;
}

.input {
  @apply w-[100%]
  h-[inherit]
  border-none
  outline-none
  bg-transparent
  rounded-[inherit]
  font-medium
  placeholder:font-normal
  placeholder:text-neutrals-3
  text-neutrals-8;
}

.input:-webkit-autofill,
.input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
