.card {
  @apply border
    shadow-2iconButton
    rounded-xl
    border-neutrals-1;
}

.mainPanel {
  @apply pt-4
    pb-5
    px-3;
}

.mainPanelLarge {
  @apply p-4
  py-5;
}

.mainPanelSingle {
  @apply flex
  flex-col
  items-center;
}

.mainPanelExpanded {
  @apply p-4;
}

.mainPanelLargeExpanded {
  @apply py-6
  px-5;
}

.title {
  @apply text-neutrals-4
    text-xs
    inline-block
    mb-2;
}

.titleLarge {
  @apply text-sm;
}

.titleExpanded {
  @apply mb-3
    text-sm;
}

.titleLargeExpanded {
  @apply text-base;
}

.balanceBox {
  @apply flex
  items-center;
}

.iconWithoutStroke {
  @apply stroke-primary-2;
}

.balanceIcon {
  @apply mr-1.5
    w-5
    h-5;
}

.balanceIconMedium {
  @apply w-6
    h-6
    mr-2;
}

.balanceIconLarge {
  @apply w-7
    mr-2
    h-7;
}

.balance {
  @apply mr-1.5
  text-xl
  font-bold
  text-neutrals-9;
}

.balanceMedium {
  @apply mr-2
    text-2xl leading-4;
}

.balanceLarge {
  @apply text-[32px]
    leading-[41.6px];
}

.percentIconSmall {
  @apply w-4
    h-4;
}

.percentIconMedium {
  @apply w-[18px]
      h-[18px];
}

.percentIconBig {
  @apply w-5
      h-5;
}

.percentIconLarge {
  @apply w-[22px]
      h-[22px];
}

.percent {
  @apply text-sm
    font-medium
    ml-1;
}

.percentLarge {
  @apply text-base;
}

.balanceWrapper {
  @apply text-green-1
    fill-green-1
    flex
    items-center;
}

.balanceWrapperNegative {
  @apply text-red-1
    fill-red-1;
}

.currency {
  @apply uppercase
  text-primary-2;
}
