.button {
  @apply flex
    relative
    transition-colors
    justify-center
    items-center;
}
/* Variants */

.details {
  @apply fill-primary-2
  text-primary-2
  hover:text-primary-1
  hover:fill-primary-1
  active:text-primary-3
  active:fill-primary-3
  font-medium
  text-sm;
}

.walletAddress {
  @apply font-semibold
  text-primary-2
  fill-neutrals-9
  hover:text-primary-1
  active:text-primary-3
  active:fill-primary-3
  md:text-lg
  md:leading-[25.2px]
  text-sm
  underline;
}

.memo {
  @apply font-semibold
  text-primary-2
  fill-neutrals-9
  hover:text-primary-1
  active:text-primary-3
  active:fill-primary-3
  md:text-lg
  md:leading-[25.2px]
  text-sm
  underline;
}

.hash {
  @apply text-neutrals-8
  hover:text-primary-1
  fill-primary-1
  active:text-primary-3
  active:fill-primary-3
  font-medium;
}

.buyCryptoHash {
  @apply font-semibold
  text-primary-2
  fill-neutrals-9
  hover:text-primary-1
  active:text-primary-3
  active:fill-primary-3
  md:text-lg
  md:leading-[25.2px]
  text-sm
  underline;
}

.default {
  @apply text-primary-2
  hover:text-primary-1
  fill-primary-1
  active:text-primary-3
  active:fill-primary-3;
}

.icon {
  @apply fill-[inherit]
  flex;
}

.left {
  @apply mr-2;
}

.right {
  @apply ml-2
  order-2;
}

.walletIcon {
  @apply ml-3;
}

.rightText {
  @apply whitespace-nowrap leading-[24px];
}
