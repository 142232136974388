.container {
  @apply relative;
}

.label {
  @apply text-neutrals-7
      bg-white
      absolute
      -top-2
      z-[1]
      px-1.5
      md:ml-[18px]
      ml-3
      max-w-max
      text-xs;
}

.row {
  @apply flex
    items-center;
}

.icon {
  @apply mr-2.5
  w-[18px];
}

.editIcon {
  @apply fill-primary-2;
}
