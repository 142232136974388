.container {
  @apply flex
    flex-col
    items-center;
}

.description {
  @apply text-xs
    text-neutrals-3
    mt-2.5
    text-center;
}
