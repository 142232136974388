.nav {
  @apply fixed
    top-0
    left-0
    right-0
    md:py-4
    py-2.5
    bg-white
    shadow-9navigation
    z-50
    w-[100%];
}

.navMobile {
  @apply fixed
  pt-2
  pb-4
  sm:pt-4
  sm:pb-8
  bottom-0
  left-0
  right-0
  border-t
  border-neutrals-1
  bg-white
    shadow-9navigation
    z-50
    w-[100%];
}

.content {
  @apply flex
  flex-row
  items-center
  justify-between;
}

.list {
  @apply flex
  relative
  w-[100%]
  md:w-[initial]
  justify-between
  md:justify-start
  top-1;
}

.item {
  @apply md:[&:not(:last-child)]:mr-10;
}

.buttons {
  @apply flex
  items-center;
}

.buttonLink {
  @apply md:mr-[60px]
  mr-4;
}

.accountPopover {
  @apply mt-0
  pl-3.5
  pr-5
  pb-2.5;
}

.selectListLink {
  @apply flex
    h-full
    w-full
    px-2
  leading-[22.4px]
  items-center;
}

.selectListLinkIcon {
  @apply fill-neutrals-9
  mr-2;
}

.downloadIcon {
  @apply stroke-neutrals-9
  fill-transparent;
}

.downloadListItem {
  @apply pointer-events-none
  flex
  cursor-auto;
}

.selectListLinkLabel {
  @apply text-tangerine-1
  text-[11px]
  leading-[14.3px]
  relative
  -top-1
  -right-1
  font-semibold;
}

.dropdownList {
  @apply pb-2;
}

.dropdownIcon {
  @apply mr-2
  fill-neutrals-9;
}

.popupWrapper {
  @apply relative
  flex
  flex-col
  items-center;
}

.tabDash {
  @apply w-[25px]
  h-[2px]
  absolute
  rounded-[10px]
  transition-colors
  -bottom-1.5
  bg-transparent;
}

.activeTabDash {
  @apply bg-primary-3;
}

.linkMobile {
  @apply flex
  flex-col
  items-center
  fill-neutrals-3
  text-neutrals-3
  stroke-neutrals-3;
}

.linkMobileActive {
  @apply text-primary-2
    fill-primary-2
    stroke-primary-2;
}

.iconMobile {
  @apply mb-0.5
  fill-transparent;
}

.linkMobileLabel {
  @apply text-[10px]
    leading-[14px]
    sm:text-sm;
}
