.form {
  @apply grid grid-cols-6 gap-7
    w-[100%]
    md:mb-9;
}

.fullWidthElement {
  @apply col-span-full;
}

.halfWidthElement {
  @apply col-span-full md:col-span-3;
}

.standardWidthElement {
  @apply col-span-full md:col-span-2;
}

.button {
  @apply w-[100%] max-w-none md:max-w-[300px] justify-self-center;
}
