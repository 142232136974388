.button {
  @apply flex
    relative
    transition-colors
    justify-center
    items-center
    bg-white
    rounded-[100px]
    px-0
    border
    border-neutrals-1
    shadow-2iconButton
    disabled:cursor-not-allowed
    fill-neutrals-9
    hover:fill-neutrals-5
    active:fill-primary-3
    disabled:bg-neutrals-2
    disabled:after:opacity-0
    disabled:fill-neutrals-4;
}

/* Size */

.medium {
  @apply h-14
    w-14;
}

.small {
  @apply h-12
    w-12;
}

.icon {
  @apply relative
  bg-transparent
  fill-inherit
  border-none
  hover:bg-transparent
  z-[1];
}

.iconWrapper {
  @apply min-w-[24px]
  min-h-[24px]
  rounded-full
  flex
  items-center
  justify-center
  bg-green-0;
}
