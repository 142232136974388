.countdown {
  @apply flex
  items-center
  justify-between
  bg-white
  rounded-[100px]
  min-h-[64px]
  py-2
  text-neutrals-9;
}

.selector {
  @apply absolute;
}

.countdown p ~ div {
  @apply rounded-[50%]
  bg-gradient-to-b
  from-gr-3-1
  to-gr-3-2
  mx-2.5
  shadow-5timeRemaining;
}

.expiredCountdown p ~ div {
  @apply bg-gradient-to-b
  from-red-0
  to-red-0
  shadow-none;
}

.countdown div div {
  @apply text-[10px];
}

.text {
  @apply text-base
    ml-6
    font-medium;
}
