.wrapper {
  @apply cursor-pointer
  flex
  justify-between
  border
  border-neutrals-1
  rounded-xl
  shadow-2iconButton
  p-5
  items-center;
}
.wrapperChecked {
  @apply border-primary-2;
}

.radio {
  @apply min-w-[22px]
  h-[22px]
  relative
  flex
  items-center
  justify-center
  border
  rounded-[50%]
  border-neutrals-2;
}
.radioDot {
  @apply w-3
  h-3
  relative
  flex
  items-center
  justify-center
  bg-neutrals-2
  rounded-[50%];
}

.title {
  @apply font-bold
  md:text-base
  md:leading-[22.4px]
  text-sm
  text-neutrals-7
  inline-block
  mb-2;
}

.description {
  @apply md:text-sm
  text-xs
  md:max-w-[500px]
  xs:max-w-[400px]
  max-w-[240px]
  text-neutrals-4;
}

.checked {
  @apply border-primary-2;
}

.checkedRadioDot {
  @apply bg-primary-2;
}

.focused {
  @apply border
  border-primary-2;
}
