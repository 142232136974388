.wrapper {
  @apply flex
  items-center
  justify-center;
}

.text {
  @apply text-sm
  text-neutrals-3;
}

.icon {
  @apply ml-2.5;
}
