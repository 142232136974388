.select {
  @apply relative;
}
.select.phone {
  @apply w-[84px]
  h-[54px];
}

.select.phoneMedium {
  @apply w-[77px]
  h-[46px]
  md:w-[84px]
  md:h-[54px];
}

.select.cryptoCurrency {
  @apply w-full
  h-[54px];
}

.select.cryptoCurrencyMedium {
  @apply w-full
  left-0
  h-12;
}

.select.cryptoCurrency .input {
  @apply bg-transparent
    rounded-[11px]
    rounded-tr-none
    rounded-br-none
    h-[54px]
    /* w-[132px] */
    absolute
    right-0
    border-none
    pl-[14px]
    pr-[12px];
}

.select.wallet .input {
  @apply bg-transparent
    rounded-xl
    md:pl-[14px]
    pr-[12px];
}

.select.cryptoCurrency .menu {
  @apply top-[60px];
}

.select.wallet .menu {
  @apply absolute
  top-[60px]
  left-0
  py-0
  right-0;
}

.select.cryptoCurrency .container {
  @apply absolute
    left-0
    right-0;
}

.select .filterContainer {
  @apply rounded-full;
}

.select.phone .input {
  @apply bg-neutrals-1
    rounded-[11px]
    rounded-tr-none
    rounded-br-none
    h-[54px]
    border-none
    pl-[14px]
    pr-[12px];
}
.select.phoneMedium .input {
  @apply bg-neutrals-1
    rounded-[11px]
    rounded-tr-none
    rounded-br-none
    h-[46px]
    border-none
    pl-[8px]
    pr-[10px];
}

.select .input {
  @apply relative
    bg-white
      outline-none
      flex
      h-[48px]
      items-center
      justify-center
      rounded-xl
      border
      cursor-pointer
      transition-colors
      border-neutrals-2
      shadow-none
      pl-1.5
      md:pr-5
      pr-3.5;
}
.select .input.focused {
  @apply border-primary-2;
}
.select .input.large {
  @apply h-[54px]
      pl-3;
}

.select .input.small {
  @apply h-[38px]
      pl-3;
}

.select .input.hosted {
  @apply w-[100%]
  pl-0
  pr-3
  left-0;
}

.select .input.currency {
  @apply w-[100%]
  pl-0
  pr-3
  border-none
  left-0;
}

.select .input.overview {
  @apply w-[100%]
  pl-1.5
  pr-3
  left-0;
}

.select .input.overviewSmall {
  @apply pl-1
  pr-2;
}

.select .input.hosted {
  @apply w-[100%]
  pl-3.5;
}

.select .input.hostedMedium {
  @apply w-[100%]
  pl-2.5;
}

.select .input.filterSelect {
  @apply rounded-full
  md:h-[42px]
  h-9
  pr-4
  pl-3;
}

.select .placeholder.filterSelect {
  @apply font-medium
        md:text-base
        text-sm
        text-neutrals-7;
}

.select .placeholder {
  @apply font-normal flex justify-end
      text-base
      text-neutrals-3;
}
.select .placeholder.large {
  @apply font-normal
          text-lg
        text-neutrals-3;
}
.select .placeholder.medium {
  @apply font-normal
          text-base
        text-neutrals-3;
}
.select .menu {
  @apply mt-[3px]
        relative
        -right-[1px]
        bg-white
        md:border
        z-[2]
        border-neutrals-2
        rounded-[10px]
        pt-[10px]
        md:pr-1.5
        pr-1
        md:shadow-8selectList;
}

.select .blurMenu {
  @apply before:bg-gradient-to-t
    before:from-gr-3-2
    before:to-gr-3-3
    before:h-10
    before:absolute
    before:bottom-0
    before:left-0
    before:z-[1]
    before:rounded-[10px]
    before:pointer-events-none
    before:w-[100%]
    before:content-[''];
}

.select .menu.filterMenu {
  @apply absolute
  left-0
  top-[50px]
  min-w-[274px];
}

.select .menuList {
  @apply pr-2 max-h-[280px]
          pt-0
          overflow-x-hidden;
}
.select .menuList::-webkit-scrollbar {
  @apply w-1
    bg-neutrals-1
    rounded;
}

.select .menuList::-webkit-scrollbar-thumb {
  @apply w-1
    bg-neutrals-2
    rounded;
}
.select .value {
  @apply text-base
        text-neutrals-8
        font-medium
        justify-end
        flex;
}

.select .hostedValue {
  @apply text-base
        text-neutrals-8
        font-medium
        justify-start
        flex;
}

.select .buyValue {
  @apply text-base
        text-neutrals-8
        font-medium
        justify-start
        flex;
}

.select .emptyValue {
  @apply text-lg
        leading-6
        text-neutrals-3
        font-normal;
}

.select .emptyValueMedium {
  @apply text-base;
}

.select .emptyValueSmall {
  @apply text-base;
}

.select.wallet .value {
  @apply md:text-lg;
}
.select .value.large {
  @apply text-lg;
}

.select.wallet .value img {
  @apply mr-0.5
  w-5
  h-5;
}

.label {
  @apply text-neutrals-7
    bg-white
    absolute
    -top-2
    z-[1]
    px-1.5
    ml-3
    max-w-max
    text-xs;
}

.labelLarge {
  @apply ml-[18px];
}

.labelDisabled {
  @apply cursor-not-allowed
    text-neutrals-3;
}

.dropdownIndicator {
  @apply fill-neutrals-5
        transition-transform;
}
.dropdownIndicator.active {
  transform: rotateX(180deg);
}

.select .emptyValueMedium div span:last-child {
  @apply left-4;
}

.flag {
  @apply w-4 h-4 rounded-full overflow-hidden mr-2.5;
}
.flag > svg {
  @apply h-4 -translate-x-1;
}

.row {
  @apply flex items-center grow;
}

.valueContainer {
  @apply flex flex-row-reverse;
}
