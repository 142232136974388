.form {
  @apply flex
    flex-col
    items-stretch
    bg-white
    md:p-11
    py-8
    px-6
    w-[100%]
    max-w-[488px]
    border
    border-neutrals-1
    rounded-[20px]
    shadow-7form;
}

.formTitleWrapper {
  @apply md:mb-5
  mb-8
  w-[100%]
  relative;
}

.arrowBack {
  @apply p-0
  absolute
  top-1
  left-1
  h-[initial]
  fill-neutrals-9;
}

.title {
  @apply text-2xl
      font-medium
      text-center
      text-neutrals-9;
}

.description {
  @apply text-neutrals-7
    self-center
      max-w-[300px]
      text-center
      md:mb-[60px]
      mb-8;
}

.descriptionLong {
  @apply max-w-[430px];
}

.contact {
  @apply font-semibold break-words;
}

.form {
  @apply flex
    flex-col
    items-center;
}

.inputDigits {
  @apply md:mb-12
  mb-11
  justify-center;
}

.centered {
  @apply mt-6
  mx-auto;
}

.checkbox {
  @apply md:mb-9
  mb-8
  ml-3
  text-sm
  text-neutrals-5;
}
