.container {
  @apply flex
  items-center
  justify-center
  max-w-max
  cursor-pointer;
}

.checkbox {
  @apply min-w-[20px]
  min-h-[20px]
  relative
  cursor-pointer
  flex
  items-center
  justify-center
  border
  fill-transparent
  rounded
  border-neutrals-3;
}

.checkboxLarge {
  @apply min-w-[22px]
  min-h-[22px];
}

.checked {
  @apply bg-primary-2
  border-primary-2
  fill-white;
}

.focused {
  @apply border
  border-primary-2;
}

.disabled {
  @apply cursor-not-allowed;
}

.icon {
  @apply fill-inherit;
}

.label {
  @apply md:ml-4
    ml-3;
}
