.wrapper {
  @apply flex
        items-center
        gap-2;
}
.input {
  @apply md:w-[48px]
        md:h-[56px]
        w-[42px]
        h-[48px]
        border
        border-neutrals-2
        rounded-[12px]
        pl-[15.5px]
        md:pl-[18px]
        text-neutrals-9
        md:text-lg;
}
.item {
  @apply text-neutrals-9
    text-lg;
}
