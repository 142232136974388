.option {
  @apply text-left
        cursor-pointer
        bg-transparent
        h-11
        md:pl-4
        pl-3
        pr-4
        outline-none
        transition-colors
        hover:text-neutrals-5
        rounded
        flex
        justify-between
        items-center
        md:text-base
        text-sm
        text-neutrals-9
        relative
        [&:last-child]:z-[1];
}
.option.focused {
  @apply text-neutrals-5;
}
.radio {
  @apply w-[18px]
        h-[18px]
        relative
        flex
        items-center
        justify-center
        border
        rounded-[50%]
        border-neutrals-2;
}
.radio.checked {
  @apply border-primary-2;
}
.radioDot {
  @apply w-[10px]
        h-[10px]
        relative
        flex
        items-center
        justify-center
        bg-neutrals-2
        rounded-[50%];
}
.checkedRadioDot {
  @apply bg-primary-2;
}

.manualEntry {
  @apply border-t
  rounded-none
  ml-4
  mb-1
  md:mt-2.5
  mt-1
  pl-0
  border-neutrals-3;
}
