.wrapper {
  @apply cursor-pointer
  flex
  justify-between
  border
  border-neutrals-2
  rounded-2xl
  md:px-6
  px-4
  py-4
  transition-shadow
  hover:shadow-2iconButton
  items-start;
}

.wrapperChecked {
  @apply border-primary-2;
}

.inner {
  @apply flex
  flex-col
  justify-between
  w-[100%];
}

.radio {
  @apply min-w-[22px]
  h-[22px]
  relative
  flex
  items-center
  justify-center
  border
  ml-4
  rounded-[50%]
  border-neutrals-2;
}

.radioDot {
  @apply w-3
  h-3
  relative
  flex
  items-center
  justify-center
  bg-neutrals-2
  rounded-[50%];
}

.title {
  @apply font-medium
  text-base
  md:text-sm
  bg:text-base
  leading-[22.4px]
  text-neutrals-9
  flex
  md:mb-2
  mb-1
  justify-between
  items-center;
}

.flexBox {
  @apply flex;
}

.icon {
  @apply bg:mr-4
  mr-2 w-[20px] h-[20px] overflow-hidden;
}

.balance {
  @apply text-base font-normal;
}

.description {
  @apply flex justify-between items-center text-xs font-normal
  text-neutrals-4
  text-center pl-[36px] pr-[38px];
}

.checked {
  @apply border-primary-2;
}

.checkedRadioDot {
  @apply bg-primary-2;
}

.focused {
  @apply border
  border-primary-2;
}

.networkSelectItem {
  @apply flex justify-start items-start cursor-pointer gap-3 w-full pb-[2px] pt-[6px];
}

.networkSelectIcon {
  @apply w-[20px] h-[20px] overflow-hidden;
}

.networkSelectContent {
  @apply flex flex-col w-full;
}

.networkSelectLabel {
  @apply text-[12px] leading-[14px] font-semibold text-neutrals-9;
}

.networkSelectDescription {
  @apply text-[12px] leading-[14px] text-neutrals-6;
}

.networkFee {
  @apply text-[10px] leading-[12px] text-neutrals-7 flex items-start justify-end min-w-[40%] pr-2;
}

.networkFeeTitle {
  @apply text-neutrals-4 mr-1;
}

.networkFeeSubTitle {
  @apply flex items-center;
}

.rightIconContainer {
  @apply flex items-center text-base font-medium;
}

.rightIcon {
  @apply w-[16px] h-[16px] mr-2;
}

.label {
  @apply ml-0 p-0 text-xs text-neutrals-7;
}

.divider {
  @apply w-full h-[1px] bg-neutrals-2 rounded-md z-10 my-4;
}

.networkSelectInput {
  @apply text-base text-neutrals-6 font-normal;
}

.selectRightIcon {
  @apply w-[20px] h-[20px] ml-2;
}
.selectRightIconBox {
  @apply flex items-center text-base font-medium;
}

.name {
  @apply text-neutrals-9 font-medium text-base;
}
