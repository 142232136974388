.button {
  @apply flex
    relative
    transition-colors
    justify-center
    whitespace-nowrap
    items-center
    rounded-[10px]
    disabled:cursor-not-allowed
    disabled:fill-neutrals-4;
}

.button.loading {
  @apply text-transparent pointer-events-none;
}

.button.loading > *:not(.loader) {
  @apply hidden pointer-events-none opacity-0;
}

.fluid {
  @apply w-full;
}

.text {
  @apply relative
    z-[1]
    font-medium;
}

.subtleText {
  @apply relative
    z-[1]
    px-2
    font-medium;
}

.link {
  @apply max-w-max;
}

/* Variants */

.primary {
  @apply bg-primary-1
    text-white
    hover:bg-primary-2
    active:bg-primary-3
    disabled:bg-neutrals-2
    disabled:after:opacity-0
    disabled:text-neutrals-4
    hover:after:opacity-0
    after:absolute
    after:transition-opacity
    after:content-['']
    after:bg-gradient-to-b
    after:from-gr-0-1
    after:to-gr-0-2
    after:block
    after:opacity-100
    after:inset-0
    after:rounded-[10px]
    fill-white;
}

.secondary {
  @apply bg-transparent
    border
    border-primary-2
    text-primary-2
    hover:border-primary-1
    hover:text-primary-1
    active:bg-primary-0
    active:border-primary-2
    active:text-primary-2
    disabled:border-neutrals-4
    disabled:text-neutrals-4
    disabled:bg-transparent
    fill-primary-2;
}

.secondaryGray {
  @apply bg-transparent
  border
  border-neutrals-4
  text-neutrals-4
  fill-primary-2;
}

.subtle {
  @apply bg-transparent
    px-0
    border-none
    text-primary-2
    hover:text-primary-1
    active:text-primary-2
    disabled:text-neutrals-4
    disabled:bg-transparent
    fill-primary-2
    h-[initial];
}

.subtleWarning {
  @apply bg-transparent
  px-0
  border-none
  text-red-2
  hover:text-red-1
  active:text-red-2
  disabled:text-neutrals-4
  disabled:bg-transparent
  fill-red-2
  h-[initial];
}

.whiteBlue {
  @apply bg-neutrals-0
  border-none
  font-medium
  text-primary-2
  hover:text-primary-1
  active:text-primary-2
  disabled:text-neutrals-4
  disabled:bg-neutrals-0;
}

.whiteCreator {
  @apply bg-neutrals-0
  border-none
  font-medium
  text-creator-2
  hover:text-creator-1
  active:text-creator-2
  disabled:text-neutrals-4
  disabled:bg-neutrals-0;
}

.whiteGreen {
  @apply bg-neutrals-0
  border-none
  font-medium
  text-green-2
  hover:text-green-1
  active:text-green-2
  disabled:text-neutrals-4
  disabled:bg-neutrals-0;
}

.blue {
  @apply bg-primary-0;
}

.subtleInner {
  @apply transition-none;
}

/* Size */

.large {
  @apply px-[60px]
    h-[56px]
    text-lg;
}

.medium {
  @apply px-[32px]
    h-[48px]
    text-base;
}

.small {
  @apply px-[32px]
    h-[40px]
    text-sm;
}

.mobile {
  @apply px-6
    h-8
    text-sm;
}

.largeSubtle {
  @apply p-0
    h-6
    text-lg;
}

.mediumSubtle {
  @apply p-0
    h-[22px]
    text-base;
}

.smallSubtle {
  @apply p-0
    h-5
    text-sm;
}

.icon {
  @apply relative
  bg-transparent
  fill-inherit
  border-none
  hover:bg-transparent
  z-[1];
}

.iconMedium {
  @apply mx-2.5;
}

.iconSmall {
  @apply mx-2;
}

.left {
  @apply ml-0;
}

.right {
  @apply mr-0
  order-2;
}

.navigationTab {
  @apply text-neutrals-5
  fill-neutrals-5
  hover:text-neutrals-5
  flex
  h-6
  items-center;
}

.active {
  @apply text-primary-3
  fill-primary-3;
}

.loader {
  @apply stroke-neutrals-0 w-4 h-4 animate-spin fill-transparent;
  stroke-dasharray: 34px;
  stroke-dashoffset: 42px;
  stroke-width: 2px;
  z-index: 1;
  stroke-linecap: round;
}
