.pagination {
  @apply flex
  items-center;
}

.pagination :global(.rc-pagination-item) {
  @apply md:text-sm
  text-xs
  font-medium
  text-neutrals-9
  mx-[5px]
  w-9
  rounded-lg
  flex
  items-center
  justify-center
  cursor-pointer
  md:h-10
  h-9;
}

.pagination :global(.rc-pagination-item-active) {
  @apply bg-primary-0;
}

.pagination :global(.rc-pagination-prev) {
  @apply fill-neutrals-3
  cursor-pointer
  w-5
  h-5;
}

.pagination :global(.rc-pagination-next) {
  @apply fill-neutrals-3
  cursor-pointer
  w-5
  h-5;
}

.pagination :global(.rc-pagination-jump-prev) {
  @apply fill-neutrals-9
  cursor-pointer
  relative
  top-[3px];
}

.pagination :global(.rc-pagination-jump-next) {
  @apply fill-neutrals-9
  cursor-pointer
  relative
  top-[3px];
}

.paginationWrapper {
  @apply flex
  md:flex-row
  flex-col
  justify-between
  px-6
  items-center;
}

.paginationInfo {
  @apply text-neutrals-4
  md:text-base
  md:leading-[22.4px]
  text-sm
  mt-4
  md:mt-0;
}
