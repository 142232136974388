.flag {
  @apply w-4 h-4 rounded-full overflow-hidden mr-2.5;
}
.flag > svg {
  @apply h-4 -translate-x-1;
}
.flag.input {
  @apply mr-2.5
      min-w-[16px];
}
.row {
  @apply flex items-center;
}
