.resultWrapper {
  @apply flex
    flex-col
    items-center
    md:px-[30px]
    md:py-5
    py-3
    md:shadow-2iconButton
    md:rounded-2xl
    w-full
    max-w-[500px];
}

.resultInner {
  @apply flex
  justify-between
  items-start
  absolute
  md:static
  bg-white
  rounded-full
  right-2
  -top-10
  shadow-6timeRemaining
  md:shadow-none
  md:rounded-none;
}

.countdownWrapper {
  @apply relative
  md:left-3;
}

.title {
  @apply mb-2
    text-neutrals-9
    font-medium;
}

.description {
  @apply text-neutrals-4
    text-sm;
}

.resultRow {
  @apply flex
  items-center w-full;
}
.underpaidResultRow {
  @apply mt-4;
}
.warningIcon {
  @apply fill-tangerine-1;
}

.warning {
  @apply md:text-xs
  text-[11px]
  leading-[14.3px]
  inline-block
  max-w-[300px]
  md:ml-3
  text-center
  md:text-left
  text-tangerine-1
  font-semibold;
}

.underpaidWarning {
  @apply md:text-xs
  text-[11px]
  leading-[14.3px]
  inline-block
  max-w-[300px]
  md:ml-3
  text-left
  md:text-left
  text-tangerine-2
  font-semibold;
}

.underpaidResultRowMoved {
  @apply mb-4;
}

.resultRowWithSeparator {
  @apply md:pt-5
  md:mt-5
  md:border-t
  md:border-neutrals-2;
}

.completedIcon {
  @apply fill-green-2
  min-w-[22px];
}

.completed {
  @apply text-green-2
  md:text-xs
  text-[11px]
  leading-[14.3px]
  text-center
  md:text-left
  md:ml-3
  font-semibold
  max-w-[350px];
}

.completedMail {
  @apply font-bold;
}
