.underlay {
  @apply md:h-full
  h-[150vh]
  w-full
  flex
  flex-col
  justify-center
  items-center;
}

.overlay {
  @apply fixed
  z-50
  left-0
  bottom-0
  right-0
  bg-black
  bg-opacity-[43%]
  flex
  items-center
  justify-center
  px-4
  overflow-auto
  h-full
  max-h-[100vh];
}

.dialog {
  @apply outline-none
    bg-white
    relative
    py-8
    px-6
    md:p-10
    rounded-[20px]
    w-full;
}
@media screen and (min-width: 480px) {
  .dialog {
    @apply w-auto;
  }
}
.closeButton {
  @apply absolute
  md:top-6
  z-[2]
  md:right-6
  top-4
  right-4;
}

.contentCard {
  @apply flex
  flex-col
  items-center;
}

.title {
  @apply mb-[18px]
  font-bold
  text-xl
  text-neutrals-9;
}

.description {
  @apply text-neutrals-5
  text-center
  mb-6
  text-base;
}

.buttons {
  @apply flex
  w-[100%]
  justify-between;
}

.footer {
  @apply w-full flex justify-center items-start;
}
