.container {
  @apply flex
      flex-col
      items-center
      md:mx-[5px];
}

.title {
  @apply text-neutrals-9
      mb-4
      md:mb-5
      font-bold
      md:text-xl
      md:leading-[26px]
      leading-[24.3px]
      text-lg;
}

.description {
  @apply text-neutrals-5
      mb-8
      max-w-[390px]
      text-center;
}

.descriptionNoMargin {
  @apply mb-0;
}

.inputField {
  @apply pl-[18px];
}

.input {
  @apply text-neutrals-8
  text-ellipsis
  pr-6
  text-base;
}

.contactButton {
  @apply mt-5;
}
