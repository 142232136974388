.wrapper {
  @apply cursor-pointer
  flex
  items-center;
}
.radio {
  @apply w-[18px]
  h-[18px]
  relative
  flex
  items-center
  justify-center
  border-[1.5px]
  rounded-[50%]
  border-neutrals-3;
}
.radioDot {
  @apply w-[10px]
  h-[10px]
  relative
  flex
  items-center
  justify-center
  bg-neutrals-2
  rounded-[50%];
}

.radioInner {
  @apply flex;
}

.iconWrapper {
  @apply flex
  items-center;
}

.icon {
  @apply mr-2.5;
}

.iconName {
  @apply inline-block
  mr-2.5
  uppercase
  text-neutrals-9
  font-semibold;
}

.label {
  @apply mr-4
  text-base
  text-neutrals-9;
}

.checked {
  @apply border-primary-2;
}

.checkedRadioDot {
  @apply bg-primary-2;
}

.radioLarge {
  @apply w-[22px]
  h-[22px];
}

.radioDotLarge {
  @apply w-3
  h-3;
}

.focused {
  @apply border
  border-primary-2;
}

.disabledWrapper {
  @apply cursor-not-allowed
  text-neutrals-2;
}
.disabledRadio {
  @apply border
  border-neutrals-2;
}
.disabledDot {
  @apply bg-neutrals-2;
}
