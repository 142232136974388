.avatar {
  @apply flex
    items-center
    justify-center
    overflow-hidden
    relative;
}

.avatarImage {
  @apply object-cover
    object-center
    rounded-[50%];
}

.placeholder {
  @apply md:text-base
  md:leading-[22.4px]
  text-sm
  font-medium
  overflow-hidden
  px-1
  text-ellipsis
  text-tangerine-1;
}

.button {
  @apply relative
  bg-tangerine-0
  rounded-[50%]
  outline-none;
}

.roleWrapper {
  @apply absolute
  flex
  items-center
  justify-center
  md:w-5
  md:h-5
  w-[14px]
  h-[14px]
  border-2
  rounded-[50%]
  border-white
  bg-pink-2
  md:-bottom-[5px]
  z-[1]
  md:-right-1
  -right-0.5
  -bottom-1;
}

.roleIcon {
  @apply fill-white;
}
