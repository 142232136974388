.paymentLoader {
  @apply flex
    flex-col
    items-center
    self-center
    bg:pl-8
    md:mr-10
    rounded-2xl;
}

.resultTitle {
  @apply text-neutrals-9
  text-2xl
  leading-[33.6px]
  mt-9
  text-center
  font-medium
  mb-4;
}

.resultRow {
  @apply flex
  items-center
  text-sm
  md:text-base;
}

.data {
  @apply text-neutrals-5
  inline-block
  whitespace-nowrap
  text-center;
}

.time {
  @apply text-neutrals-4
  whitespace-nowrap
  ml-2;
}

.hostedPage {
  @apply max-w-[290px]
  flex
  flex-col
  items-center;
}

.hostedPaymentLoader {
  @apply md:mr-5;
}

.monitoredBy {
  @apply mt-[22px];
}
.banner {
  @apply max-w-none;
}

.checkoutDataTitle {
  @apply text-neutrals-5
    block
    text-xs;
}

.checkoutDataMemoTitle {
  @apply text-neutrals-5
    block
    text-xs
    font-semibold;
}

.qrWalletWrapper {
  @apply mb-2 pl-5 flex justify-start items-center;
}

.qrMemoWrapper {
  @apply flex justify-center items-center w-full p-3 bg-tangerine-0 rounded-md mb-4;
}

.walletSaveWarning {
  @apply text-xs text-red-500 mb-2 text-center px-6;
}

.mobileQRWrapper {
  @apply flex justify-center items-center;
}
.copyButton {
  @apply text-xs leading-[inherit] !important;
}
.copyIcon {
  @apply min-w-[10px] w-[15px] !important;
}
.memoWrapper {
  @apply flex justify-center items-center;
}

.memoWarningIcon {
  @apply w-[20px] h-[20px] mr-1 cursor-pointer fill-red-500;
}

.memoWarningText {
  @apply text-xs text-[10px] font-medium text-red-500;
}

.memoWarningWrapper {
  @apply flex justify-center items-center;
}
