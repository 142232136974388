.tabs {
  @apply flex
  flex-col;
}

.verticalTabs {
  @apply flex-row;
}

.tabList {
  @apply flex z-0;
}

.sellTabList {
  @apply md:mb-9
  mb-8
  md:py-2
  py-1
  md:px-1
  border
  border-neutrals-2
  rounded-xl
  shadow-2iconButton;
}

.dateTabList {
  @apply mb-7
  w-max
  px-4
  border
  border-neutrals-7
  rounded-[100px];
}

.verticalTabList {
  @apply flex-col;
}

.tab {
  @apply text-neutrals-4
  flex
  items-center  
  text-sm
  font-normal
  px-1
  whitespace-nowrap
  w-max
  cursor-pointer
  transition-colors;
}

.selectedTab {
  @apply text-primary-2
  font-semibold;
}

.verticalTab {
  @apply mb-2;
}

.roundedTab {
  @apply px-5
  py-3
  rounded-[100px]
  bg-transparent
  md:text-base
  md:leading-[22.4px]
  text-sm
  font-medium
  text-primary-3;
}

.selectedRoundedTab {
  @apply bg-primary-0;
}

.squareTab {
  @apply px-5
  py-2
  rounded-[12px]
  bg-transparent
  text-base
  leading-[22.4px]
  font-medium
  fill-neutrals-5
  text-neutrals-5;
}

.selectedSquareTab {
  @apply bg-primary-0
  text-primary-3
  fill-primary-3;
}

.sellTab {
  @apply w-[100%]
  flex
  justify-center
  py-2
  rounded-[7px]
  bg-transparent
  md:text-base
  md:leading-[24.2px]
  text-sm
  font-medium
  mx-1
  text-neutrals-5;
}

.selectedSellTab {
  @apply text-neutrals-9
  bg-primary-0;
}

.dateTab {
  @apply w-[100%]
  flex
  justify-center
  py-2.5
  px-3
  bg-transparent
  text-sm
  leading-[16.1px]
  font-medium
  text-neutrals-7;
}

.selectedDateTab {
  @apply text-neutrals-0
  bg-neutrals-7;
}

.headerTab {
  @apply font-medium
  text-base
  flex-col
  fill-neutrals-5
  text-neutrals-5;
}

.navigationTab {
  @apply flex;
}

.navigationTabInner {
  @apply flex;
}

.selectedHeaderTab {
  @apply text-primary-3
  fill-primary-3;
}

.headerTabInner {
  @apply flex
  mb-1;
}

.tabChevron {
  @apply ml-1
  transition-none
  fill-[inherit];
}

.tabDash {
  @apply w-[25px]
  h-[2px]
  rounded-[10px]
  transition-colors
  bg-transparent;
}

.activeTabDash {
  @apply bg-primary-3;
}

.tabIcon {
  @apply mr-2;
}

.closeButton {
  @apply ml-4;
}

.closeButtonIcon {
  @apply fill-neutrals-7;
}

.tabPanel {
  @apply flex
  flex-col
  bg-white
  items-center
  w-[100%];
}
