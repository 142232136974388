.flag {
  @apply w-4 h-4 rounded-full overflow-hidden mr-2.5;
}
.flag > svg {
  @apply h-4 -translate-x-1;
}
.flag.input {
  @apply mr-2.5
        min-w-[16px];
}
.row {
  @apply flex items-center;
}
.selectValue > div > span {
  @apply hidden;
}
.selectValue > div > div {
  @apply mr-0;
}
.container {
  @apply relative
    bg-white
    flex
    w-full
    h-[56px]
    items-center
    justify-center
    rounded-xl
    border
    cursor-pointer
    transition-colors
    border-neutrals-2
    shadow-none
    font-medium
    text-lg
    pl-1.5
    pr-5;
}

.medium {
  @apply h-12
  p-0;
}

.container.focused {
  @apply border-primary-1;
}
.select {
  @apply absolute
  left-0;
}
.input {
  @apply outline-none
  border-none
  h-[54px]
  text-neutrals-8
  w-full
  rounded-[11px]
    rounded-tl-none
    rounded-bl-none;
}

.inputMedium {
  @apply h-[46px]
  text-base;
}

.inputWrapper {
  @apply absolute
  left-[96px]
  right-0
  top-0
  bottom-0
  flex
  justify-start
  items-center;
}
.country {
  @apply mr-1;
}
.labelWrapper {
  @apply absolute
    px-2
    left-[14px]
    top-[-8px]
    z-10
    text-neutrals-7
    font-normal
    text-xs
    before:content-['']
    before:flex
    before:bg-neutrals-1
    before:absolute
    before:left-0
    before:bottom-[1px]
    before:z-[9]
    before:h-[8px]
    before:w-[70px]
    after:content-['']
    after:flex
    after:bg-white
    after:absolute
    after:left-[70px]
    after:right-0
    after:bottom-[1px]
    after:z-[9]
    after:h-[8px];
}

.labelWrapperMedium {
  @apply left-[10px]
  font-normal
  px-1.5;
}
.label {
  @apply relative
    z-[10];
}
.labelwrapper ::before {
  @apply content-['']
    flex
    bg-red-500
    absolute
    left-0
    top-0
    z-20
    h-[8px]
    w-[100px];
}

.input:-webkit-autofill,
.input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.error {
  @apply absolute top-[50px] md:top-16 left-0;
}

.errorText {
  @apply text-red-2
  ml-2
  md:text-sm
  text-xs
  font-normal;
}
