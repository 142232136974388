.container {
  @apply shadow-7form
  rounded-[20px]
  bg-white
  md:p-[40px]
  w-full
  px-6
  py-8
  md:pb-[40px]
  pb-[32px]
  relative;
}

.centered {
  @apply mx-auto;
}
