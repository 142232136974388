.loader {
  @apply flex
    flex-col
    max-w-[314px]
    md:mt-[108px]
    mt-[60px]
    mx-auto
    items-center;
}

.loaderCentered {
  @apply flex
    flex-col
    max-w-[314px]
    md:mt-[108px]
    mx-auto
    items-center
    justify-center;
}

.loaderTitle {
  @apply text-neutrals-9
    font-medium
    text-2xl
    md:text-[32px]
    md:leading-[44.8px]
    mt-12
    md:mt-10
    mb-4
    md:mb-5;
}

.loaderDescription {
  @apply text-neutrals-7
    md:text-lg
    text-base
    leading-[24.3px]
    text-center;
}
