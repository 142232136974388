.radio {
  @apply w-[18px]
    h-[18px]
    relative
    flex
    items-center
    justify-center
    border
    rounded-[50%]
    border-neutrals-2;
}
.radioDot {
  @apply w-[10px]
    h-[10px]
    relative
    flex
    items-center
    justify-center
    bg-neutrals-2
    rounded-[50%];
}
.checked {
  @apply border-primary-2;
}

.checkedRadioDot {
  @apply bg-primary-2;
}

.listContainer::-webkit-scrollbar,
.listBox::-webkit-scrollbar {
  @apply w-1
    bg-neutrals-1
    rounded;
}

.listContainer::-webkit-scrollbar-thumb,
.listBox::-webkit-scrollbar-thumb {
  @apply w-1
    bg-neutrals-2
    rounded;
}

.listBox {
  @apply pr-2;
}

.isScrollable {
  @apply overflow-y-auto
    pr-3;
}

.option {
  @apply text-left
    cursor-pointer
    bg-transparent
    min-h-[44px]
    px-2
    outline-none
    transition-colors
    hover:bg-neutrals-1
    hover:text-neutrals-5
    rounded
    flex
    justify-between
    items-center
    text-base
    text-neutrals-9
    [&:last-child]:mb-2.5;
}

.withIcon {
  @apply h-[initial]
    leading-[22px]
    py-[9px];
}
