.toggleWrapper {
  @apply inline-flex
    border
    border-primary-2
    rounded-full
    relative
    px-1
    py-1.5;
}

.toggleWrapperSmall {
  @apply py-[5px];
}

.toggleItem {
  @apply cursor-pointer
  rounded-full
  border
  border-transparent
  px-2.5
  font-medium
  text-sm
  text-primary-2
  transition-all
  py-[3px];
}

.small {
  @apply text-xs
  pb-[2px]
  pt-[3px]
}

.toggleItemFirstChild {
  @apply mr-1;
}

.toggleItemChecked {
  @apply bg-primary-2
  text-white;
}

.focused {
  @apply border-primary-2;
}
